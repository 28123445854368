<template>
  <div>
    <b-badge
      v-if="tagNames.length == 0"
      variant="light-primary"
      class="align-content-center mb-1"
      style="marginRight: 5px"
    >Unassigned</b-badge>

    <template v-else>
      <b-badge
        v-for="(tag, index) in tagNames"
        :id="`tag-${index}`"
        :key="tag.id"
        class="align-content-center mb-1"
        style="marginRight: 5px"
        :style="{
          backgroundColor: getBackgroundColor(tag.color),
          color: tag.color,
        }"
      >
        {{ tag.name | cutString }}
        <b-tooltip
          v-if="tag.name.length > 20"
          :target="`tag-${index}`"
          triggers="hover"
          variant="light"
        >
          {{ tag.name }}
        </b-tooltip>
      </b-badge>
    </template>

  </div>

</template>

<script>

import {
  BBadge,
  BTooltip,
} from 'bootstrap-vue'

export default {
  components: {
    BBadge,
    BTooltip,
  },
  filters: {
    cutString(val) {
      let string = val
      if (val) {
        if (string.length > 20) {
          string = `${string.slice(0, 17)} ...`
        }
      }
      return string
    },
  },
  props: {
    tagNames: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getBackgroundColor(color) {
      return `${color}44`
    },
  },
}
</script>

<style lang="scss">

</style>
